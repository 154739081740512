(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller(
            "EAccountCouponsDetailController",
            EAccountCouponsDetailController
        );

    EAccountCouponsDetailController.$inject = [
        "$http",
        "$scope",
        "Principal",
        "$rootScope",
        "$state",
        "$stateParams",
        "pagingParams",
        "TripSearch",
        "paginationConstants",
        "ParseLinks",
        "AlertService",
        "previousState",
        "OneEAccountRecords",
    ];

    function EAccountCouponsDetailController(
        $http,
        $scope,
        Principal,
        $rootScope,
        $state,
        $stateParams,
        pagingParams,
        TripSearch,
        paginationConstants,
        ParseLinks,
        AlertService,
        previousState,
        OneEAccountRecords
    ) {
        var vm = this;
        var eid;
        if ($stateParams.eId) {
            eid = $stateParams.eId;
        } else {
            var arr = window.location.href.split("/");
            eid = arr[arr.length - 1];
        }

        getAccountInfo();
        function getAccountInfo() {
            $http({
                method: "GET",
                url: "/api/e-accounts/" + eid,
            }).then(
                function (res) {
                    console.log(res.data);
                    vm.eAccount = res.data;
                    loadAll();
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        vm.IMAGE_SERVER = IMAGE_SERVER;
        //vm.eAccount = entity1;
        //vm.eAccountRecords = entity2;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.radioEvent = radioEvent;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        // vm.itemsPerPage=3
        vm.filterTrip = this.filterTrip;
        this.filterTrip = function (search) {
            if (search) {
                vm.trips = TripSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                    release: "1",
                });
            }
        };
        vm.explain = "";
        vm.price = null;
        vm.radioValue = 0;
        vm.freeCoupons = 0;
        vm.couponsReturn = null;
        vm.tripId = null;

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    }
                }
            });
        }

        // 切换充值类型
        function radioEvent() {
            // radioEvent
            document.getElementsByName("coupontype").forEach(function (item) {
                if (item.checked) {
                    vm.radioValue = Number(item.value);
                }
            });
            if (vm.radioValue === 0) {
                document.getElementById("coupon2").style.display = "none";
                document.getElementById("coupon1").style.display = "block";
            } else {
                document.getElementById("coupon1").style.display = "none";
                document.getElementById("coupon2").style.display = "block";
            }
        }

        // vm.currentSearch = vm.eAccount.id;
        function loadAll() {
            OneEAccountRecords.query(
                {
                    query: vm.eAccount.id,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    release: "0",
                },
                onSuccess,
                onError
            );
            function sort() {
                var result = ["desc"];
                // if (vm.predicate !== 'updateDate') {
                //     result.push('updateDate');
                // }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.eAccountRecords = data;
                console.log(
                    "充值记录数据vm.eAccountRecords",
                    vm.eAccountRecords
                );
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            console.log("页数：" + vm.page);
            pagingParams.page = vm.page;
            loadAll();
            // $state.transitionTo($state.$current, {
            //     page: vm.page,
            //     sort: vm.predicate + ',' +'desc',
            //     search: vm.eAccount.id
            // });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.keyDown = function (event) {
            if (event.keyCode == 13) {
                vm.search(vm.searchQuery);
            }
        };

        $scope.$watch("vm.price", function (newValue, oldValue) {
            if (!/^\d+$/.test(newValue)) {
                vm.price = null;
            }
        });
        $scope.$watch("vm.couponsRecharge", function (newValue, oldValue) {
            if (!/^\d+$/.test(newValue)) {
                vm.couponsRecharge = null;
            }
        });

        $scope.$watch("vm.freeCoupons", function (newValue, oldValue) {
            if (!/^\d+$/.test(newValue)) {
                vm.freeCoupons = null;
            }
        });
        vm.onceRecharge = true;
        vm.rechargeCoupons = rechargeCoupons;
        function rechargeCoupons() {
            if (!vm.onceRecharge) {
                return false;
            }
            vm.onceRecharge = false;
            var url = "";
            if (vm.radioValue === 0) {
                if (vm.couponsRecharge == null) {
                    vm.errMsg2 = "请输入充值点数";
                } else if (vm.price == null) {
                    vm.errMsg2 = "请输入充值金额";
                } else if (vm.explain == "") {
                    vm.errMsg2 = "请输入备注信息";
                } else {
                    url =
                        "/api/e-accounts/recharge-coupons/" +
                        vm.eAccount.id +
                        "?explain=" +
                        vm.explain +
                        "&couponsRecharge=" +
                        vm.couponsRecharge +
                        "&price=" +
                        vm.price +
                        "&freeCoupons=0";
                }
            } else {
                if (vm.freeCoupons == null) {
                    vm.errMsg2 = "请输入赠送点数";
                } else if (vm.explain == "") {
                    vm.errMsg2 = "请输入备注信息";
                } else {
                    url =
                        "/api/e-accounts/recharge-coupons/" +
                        vm.eAccount.id +
                        "?explain=" +
                        vm.explain +
                        "&couponsRecharge=0&price=0&freeCoupons=" +
                        vm.freeCoupons;
                }
            }
            if (url) {
                vm.errMsg2 = null;
                $http({
                    method: "POST",
                    url: url,
                }).then(
                    function (res) {
                        console.log(res);
                        if (res.data.errorCode == 0) {
                            vm.explain = "";
                            vm.price = null;
                            vm.freeCoupons = 0;
                            vm.couponsReturn = null;
                            $("#rechargeCoupons").modal("hide");
                            window.setTimeout(function () {
                                $state.go(
                                    "e-account-coupons-detail",
                                    { eId: vm.eAccount.id },
                                    { reload: true }
                                );
                            }, 300);
                        }
                        vm.onceRecharge = true;
                    },
                    function (err) {
                        vm.onceRecharge = true;
                        console.log(err);
                    }
                );
            } else {
                vm.onceRecharge = true;
            }
        }

        vm.returnCoupons = returnCoupons;
        function returnCoupons() {
            if (!vm.tripId) {
                vm.errMsg = "请选择活动";
                return false;
            }
            if (!vm.couponsReturn) {
                vm.errMsg = "请输入点数";
                return false;
            }
            if (!vm.explain) {
                vm.errMsg = "请输入备注信息";
                return false;
            }
            vm.errMsg = null;
            $http({
                method: "POST",
                url:
                    "/api/e-accounts/return-coupons/" +
                    vm.eAccount.id +
                    "?explain=" +
                    vm.explain +
                    "&tripId=" +
                    vm.tripId +
                    "&couponsReturn=" +
                    vm.couponsReturn +
                    "&price=" +
                    vm.price,
            }).then(
                function (res) {
                    if (res.data.errorCode == 0) {
                        $("#returnCoupons").modal("hide");
                        window.setTimeout(function () {
                            $state.go(
                                "e-account-coupons-detail",
                                { eId: vm.eAccount.id },
                                { reload: true }
                            );
                        }, 300);
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:eAccountUpdate",
            function (event, result) {
                vm.eAccount = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
